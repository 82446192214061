.playground {
  // padding: 1px 0 0 1px;
}

.flex-page_flex-child {
  border: 1px dashed blue;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: -1px;
  // margin-left: -1px;

  &.single-child {
    border: 1px solid red;
    position: relative;
    z-index: 1;
    // margin: 0 1px 1px 0;
  }
}

hr {
  margin: 10px 0;
}

.category {
  font-size: 24px;
}

.category-children {
  color: blue;
}

.category-child {
  color: red;
}