/* Range */
.cc-range-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cc-range-slider-input {
  cursor: pointer;
  flex: 1;
}

.cc-range-slider output {
  font-size: 1.2rem;
  color: #333;
}

.cc-range-text-top {
  flex-direction: column-reverse;
}

.cc-range-text-right {
  flex-direction: row;
  gap: 8px;
}

.cc-range-text-left {
  flex-direction: row-reverse;
  gap: 8px;
}

.cc-range-slider-input {
  -webkit-appearance: none;
  width: 100%;
}

.cc-range-slider-input::-webkit-slider-runnable-track {
  width: var(--cc-range-width);
  height: 5px;
  background: var(--cc-range-secondary-color);
  border: none;
  border-radius: 3px;
}

.cc-range-slider-input::-moz-range-track {
  width: var(--cc-range-width);
  height: 5px;
  background: var(--cc-range-secondary-color);
  border: none;
  border-radius: 3px;
}

.cc-range-slider-input::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: var(--cc-range-thumb-size);
  width: var(--cc-range-thumb-size);
  border-radius: 50%;
  background: var(--cc-range-primary-color);
  margin-top: calc(3px + var(--cc-range-thumb-size) / -2);
}

.cc-range-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: var(--cc-range-thumb-size);
  width: var(--cc-range-thumb-size);
  border-radius: 50%;
  background: var(--cc-range-primary-color);
  margin-top: calc(3px + var(--cc-range-thumb-size) / -2);
}