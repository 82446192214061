/* Input */
.cc-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cc-input-label {
  font-size: 14px;
  line-height: 12px;
}

.cc-input-element {
  height: 32px;
  width: 100%;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid black;
  box-sizing: border-box;
}

.cc-input-error {
  font-size: 12px;
  line-height: 10px;
  height: 10px;
  color: #db3838;
}

.cc-input-error.hidden {
  opacity: 0;
}