/* Toggle */
.cc-toggle-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cc-toggle {
  width: 40px;
  height: 20px;
  background: #FFF;
  border: 1px solid #2d2f31;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.cc-toggle.cc-toggle-on {
  background: #FFF;
}

.cc-toggle.cc-toggle-on .cc-toggle-indicator {
  left: calc(100% - 18px);
}

.cc-toggle-indicator {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  background: #2d2f31;
  border: 1px solid #2d2f31;
  border-radius: 50%;
  transition: 0.3s ease;
}

.cc-toggle-label {
  font-size: 14px;
}