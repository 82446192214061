/* Slideout */
.cc-slideout-overlay {
  border: none;
  background: rgba(0,0,0,0.2);
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cc-slideout {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #FFF;
  color: #000;
  box-shadow: 0 0 10px -6px #000;
  z-index: 10;
  padding: 32px;
  transform: translateX(100%);
  transition: 0.3s ease-in-out;

  @media screen and (max-width: 500px) {
    width: auto !important;
    left: 0;
  }
}

.cc-slideout-open .cc-slideout-overlay {
  display: block;
}

.cc-slideout-open .cc-slideout {
  transform: translateX(0);
}

.cc-slideout-closed .cc-slideout-overlay {
  display: none;
}

.cc-slideout-dialog .cc-slideout-body {
  padding: 8px 36px 8px 12px;
}

.cc-slideout-header {
  border-bottom: 1px solid #000;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.cc-slideout-body {
  padding: 16px;
  overflow: auto;
  height: calc(100% - 16px);
}

.cc-slideout-body *:last-child {
  margin-bottom: 0;
}

.cc-slideout-title {
  flex: 1;
}

.cc-slideout-close{
  background: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>');
  width: 24px;
  height: 24px;
  border: none;
}