/* Button */
.cc-button {
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  cursor: pointer;
  border: 1px solid #000;
  transition: 0.25s;
  justify-content: center;
  background-color: #FFF;
  text-decoration: none;
  color: #000;
}

.cc-button:hover {
  background-color: #33beb8;
  color: #fff;
}

.cc-button path {
  transition: 0.25s;
}

.cc-button:hover path {
  fill: #fff;
}

.cc-button-full-width {
  width: 100%;
  box-sizing: border-box;
}

.cc-button-small {
  font-size: 16px;
  padding: 4px 16px;
}

.cc-button-large {
  font-size: 24px;
}

.cc-button-left {
  justify-content: flex-start;
}

.cc-button-right {
  justify-content: flex-end;
}

.cc-button-primary {
  background: #33beb8;
  color: #FFF;
}

.cc-button-primary:hover {
  background: #FFF;
  color: #000;
}

.cc-button-transparent {
  border: none;
  background: none;
  color: #000;
  padding: 4px 0;
}

.cc-button-transparent:hover {
  text-decoration: underline;
  background: none;
  color: #000;
}