/* Table */
.cc-table {
  display: grid;
  border-collapse: collapse;
  max-width: 100%;
  overflow: auto;
  color: #000;
  border-radius: 4px;
}

.cc-table thead,
.cc-table tbody,
.cc-table tr {
  display: contents;
}

.cc-table th,
.cc-table td {
  padding: 16px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cc-table th {
  position: sticky;
  top: 0;
  background: #EFEFEF;
  text-align: left;
  font-weight: normal;
  font-size: 1.1rem;
}

.cc-table th:last-child {
  border: 0;
}

.cc-table td {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #808080;
  background: #FFF;
  font-weight: 300;
  border-right: 1px solid #EFEFEF;
}

.cc-table td:last-child {
  border: none;
}

.cc-table-row-labels td:first-child {
  font-weight: 500;
  color: #000;
}

.cc-table tr:nth-child(even) td {
  background: #F9F9F9;
}