// Popover
.cc-popover-container {
  position: relative;
}

.cc-popover-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cc-popover-close {
  background: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>');
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: none;
}

.cc-popover {
  position: absolute;
  z-index: 10;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
  
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #FFF;
  background-color: var(--popover-color);
  box-shadow: 0 0 10px -5px #000;
  min-width: 100px;
}

.cc-popover-content > * {
  margin: 0;
  white-space: nowrap;
}

.cc-popover-blank {
  color: #000;
}

.cc-popover-blank .cc-popover-close {
  background-image: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>');
}

.cc-popover::before,
.cc-popover::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}

// Top
.cc-popover-top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.cc-popover-top::before {
  top: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
  border-top: 8px solid #ddd;
}

.cc-popover-top::after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 8px solid var(--popover-color);
}

// Right
.cc-popover-right {
  left: calc(var(--target-width) + 10px);
  top: calc(var(--target-height)/2);
  transform: translateY(-50%);
}

.cc-popover-right::before {
  top: 50%;
  right: calc(100% + 1px);
  transform: translateY(-50%);
  border-right: 8px solid #ddd;
}

.cc-popover-right::after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-right: 8px solid var(--popover-color);
}

// Bottom
.cc-popover-bottom {
  top: calc(var(--target-height) + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.cc-popover-bottom::before {
  bottom: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid #ddd;
}

.cc-popover-bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid var(--popover-color);
}

// Left
.cc-popover-left {
  right: calc(var(--target-width) + 10px);
  top: calc(var(--target-height)/2);
  transform: translateY(-50%);
}

.cc-popover-left::before {
  top: 50%;
  left: calc(100% + 1px);
  transform: translateY(-50%);
  border-left: 8px solid #ddd;
}

.cc-popover-left::after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-left: 8px solid var(--popover-color);
}