/* Checkbox */
.cc-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cc-checkbox-input:focus + .cc-checkbox-box {
  outline: 2px solid -webkit-focus-ring-color; /* WebKit */
  outline: 2px solid -moz-mac-focusring; /* Firefox on macOS */
  outline: 2px solid -moz-focus-inner; /* Firefox */
  outline: 2px auto -webkit-focus-ring-color;
}

.cc-checkbox.disabled .cc-checkbox-box {
  background-color: #AAA;
}

.cc-checkbox.disabled .cc-checkbox-icon {
  background-image: none;
}

.cc-checkbox * {
  cursor: pointer;
}

.cc-checkbox-input {
  position: absolute;
  opacity: 0;
}

.cc-checkbox-box {
  height: 20px;
  width: 20px;
  background-color: #FFF;
  border: 1px solid #000;
  border-radius: 4px;
}

.cc-checkbox-input:checked + .cc-checkbox-box {
  background-color: #2b7946;
}

.cc-checkbox-icon {
  width: 20px;
  height: 20px; 
  background-image: url('data:image/svg+xml;utf8,<svg class="check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z"></path></svg>');
}

.cc-checkbox-label {
  color: #000;
}
