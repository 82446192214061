.box-model-page {

  .description ul {
    padding-left: 20px;
    margin: 10px 0;
    list-style: disc;
  }
  
  .playground {
    min-height: 300px;
  }
  
  .playground div::after {
    margin-top: -10px;
  }
  
  .playground-options {
    padding-top: 8px;
  }
  
  .playground-margin {
    box-sizing: border-box;
  }
  
  .playground-margin::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 100px;
    border-top: 4px solid red;
    transform: translateY(-55px);
  }
  
  .playground-margin::after {
    content: attr(data-playground-margin);
    position: absolute;
    top: 50%;
    right: calc(100% + 110px);
    white-space: nowrap;
    color: red;
    transform: translateY(-55px);
  }
  
  .playground-border::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 100px;
    border-top: 4px solid blue;
    transform: translateY(-25px);
  }
  
  .playground-border::after {
    content: attr(data-playground-border);
    position: absolute;
    top: 50%;
    right: calc(100% + 110px);
    white-space: nowrap;
    color: blue;
    transform: translateY(-25px);
  }
  
  .playground-padding::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 100px;
    border-top: 4px solid green;
    transform: translateY(5px);
  }
  
  .playground-padding::after {
    content: attr(data-playground-padding);
    position: absolute;
    top: 50%;
    right: calc(100% + 110px);
    white-space: nowrap;
    color: green;
    transform: translateY(5px);
  }
  
  .playground-content {
    background: #a469fb;
  }
  
  .playground-content::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 90px;
    height: 4px;
    background-color: #a469fb;
    transform: translateY(35px);
    z-index: 2;
  }
  
  .playground-content::after {
    content: attr(data-playground-content);
    position: absolute;
    top: 50%;
    right: calc(100% + 100px);
    white-space: nowrap;
    color: #a469fb;
    transform: translateY(35px);
  }
  
  // .playground-content::after {
  //   content: var(--content-content);
  //   position: absolute;
  //   top: 50%;
  //   left: calc(100% + 110px);
  //   white-space: nowrap;
  //   color: black;
  //   transform: translateY(-10px);
  //   margin-top: -17px !important;
  //   white-space: pre;
  // }
  
  .purps {
    color: #a469fb;
  }
  
  .blue {
    color: blue;
  }
  
  .red {
    color: red;
  }
  
  .green {
    color: green;
  }
  
  .box-sizing-desc {
    position: absolute;
    left: calc(100% + 60px);
    white-space: nowrap;
    border: 1px solid black;
    padding: 4px 8px;
    border-radius: 4px;
    background: #fff;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
  
    hr {
      margin: -6px 0 4px 0;
    }
  
    p {
      margin: 0 0 10px;
  
      &:last-child {
        margin: 0;
      }
    }
  }
  
  .playground-text {
    
    span {
      padding: 0 6px;
      background: #a469fb;
      z-index: 1;
      color: white;
      position: relative;
    }
  }
  
  .playground-text::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    border-left: 2px dashed white;
    margin-left: -1px !important;
  }
  
  .playground-text::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 2px dashed white;
    margin-top: -1px !important
  }
}
