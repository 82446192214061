.grid-page {

  .playground {
    height: 240px;
  }

  .wrapper {
    background-color: #fff;
    color: #444;
    padding: 15px;
    width: 100%;
  }

  .category-child-1 {
    color: #40a4d8;
  }

  .category-child-2 {
    color: #a363d9;
  }

  .category-child-3 {
    color: #fecc2f;
  }

  .category-child-4 {
    color: #ee657a;
  }

  .grid-item-1 {
    background-color: #40a4d8;
  }

  .grid-item-2 {
    background-color: #a363d9;
  }

  .grid-item-3 {
    background-color: #fecc2f;
  }

  .grid-item-4 {
    background-color: #ee657a;
  }

  /* Grid CSS */
  .wrapper {
    display: grid;
  }

  .grid-item {
    color: #fff;
    border-radius: 5px;
    padding: 20px;
    font-size: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid-item-1 {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .grid-item-2 {
    grid-column: 3 ;
    grid-row: 1 / 3;
  }
  .grid-item-3 {
    grid-column: 1 ;
    grid-row: 2 ;
  }
  .grid-item-4 {
    grid-column: 2;
    grid-row: 2;
  }
}
